import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,

  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,

  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,

  databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,

  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,

  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,

  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
};

const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);

export const pendingReviewsCollectionRef = collection(
  firestore,
  "PendingReviews"
);

export const approvedReviewsCollectionRef = collection(
  firestore,
  "ApprovedReviews"
);
export const discardedReviewsCollectionRef = collection(
  firestore,
  "DiscardedReviews"
);

export const auth = getAuth(app);

export const db = getDatabase(app);
